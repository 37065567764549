import React from 'react';
import { Helmet } from "react-helmet";

function OrganisationSchema() {
    const organisationSchema = {
        "@context": "https://schema.org",
        "@type": "OnlineBusiness",
        "name": "SPORT 2000",
        "description": "Im SPORT 2000 Onlineshop finden Sie in großes Sortiment von Sportbekleidung und Ausrüstung sowie Angebote von über 1.000 lokalen Sporthändler:innen in ganz Deutschland. Sie alle haben eines gemeinsam: Leidenschaft für den Sport.",
        "legalname": "SPORT 2000 GmbH",
        "alternateName": "SPORT 2000",
        "url": "https://www.sport2000.de/",
        "logo": "https://res.cloudinary.com/dlwdq84ig/image/upload/zhp8z0juvlpeazvcooiz",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Nord-West-Ring-Str. 11",
            "addressLocality": "Mainhausen",
            "addressRegion": "Hessen",
            "postalCode": "63533",
            "addressCountry": "Germany"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "061829282274",
            "contactType": "customer service",
            "contactOption": "TollFree",
            "areaServed": "DE",
            "availableLanguage": "German"
        },
        "sameAs": [
            "https://www.facebook.com/SPORT.2000.Deutschland/",
            "https://www.youtube.com/channel/UC3mywbYSk63OL4oTfF369hQ",
            "https://de.linkedin.com/company/sport-2000-gmbh",
            "https://de.wikipedia.org/wiki/Sport_2000",
            "https://www.instagram.com/sport2000de/",
            "https://www.sport2000.de/"
        ]
    }
    return (
        <>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(organisationSchema)}</script>
            </Helmet>
        </>
    );
}

export default OrganisationSchema;
